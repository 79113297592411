import { LocationInfo } from 'components/countries/LocationInfo';
import { CourseContext } from 'contexts/CourseContext';
import { ProfileContext, Role } from 'contexts/ProfileContext';
import { firebaseStringToRole, isATypeOfStudent, roleToAdminString } from 'contexts/ProfileUtil';
import { UiTrainingContext } from 'contexts/UiTrainingContext';
import { SplashCardRight } from 'course/components/CourseCard';
import { useCourseId } from 'hooks/router/useUrlParams';
import { Steps } from 'intro.js-react';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useIsMobile } from 'utils/general';
import { CoursePageBodyContainer } from '../../components/layout/CoursePageBodyContainer';
import { ModuleTodosSection } from './components/ModuleTodosSection';
import { filterTraining, studentHomeUiTraining } from './uiTraining/studentHomeTraining';

export const NewStudentHome = () => {
  const isMobile = useIsMobile()
  return (<>
    <UiTraining />
    <CoursePageBodyContainer
      mainColumn={<StudentSplashMain />}
      rightColumn={<StudentSplashRightCol />}
      singleColumn={<StudentSplashMain />}
    />
  </>);
}

const UiTraining = () => {
  const uiKey = 'hasSeenStudentHome'
  const {userAge} = useContext(ProfileContext)
  const {markComplete, hasTraining, uiTrainingLoading} = useContext(UiTrainingContext)
  const isMobile = useIsMobile()

  if (isMobile) return <></>
  if(uiTrainingLoading) return <></>
  if(hasTraining(uiKey)) return <></>

  const trainingSteps = filterTraining(studentHomeUiTraining, userAge)

  return <Steps
    enabled={true}
    steps={trainingSteps}
    initialStep={0}
    options={{
      showBullets: false,
    }}
    onExit={() => {
      markComplete(uiKey)
    }}
  />
}


export const StudentSplashMain = () => {
  const { userData } = useContext(ProfileContext)
  const { roadmapList } = useContext(CourseContext)
  const courseRole = userData.courseRole
  const [toggleRole, setToggleRole] = useState(Role.STUDENT)
  const options = roadmapList
          .map((role) => firebaseStringToRole(role))
          .filter((role) => role !== Role.SECTION_LEADER)



  if (isATypeOfStudent(courseRole)) {
    return <ModuleTodosSection filterRole={courseRole} />
  }


  

  return <div >

 

    {roadmapList.length > 2 && (
    <select 
      value={toggleRole} 
      onChange={(e) => setToggleRole(parseInt(e.target.value))} 
      className='form-select'
      style={{
        width: "200px",
      }}
    >
      
      {options.map((role) => <option key={role} value={role}>{roleToAdminString(role)}</option>)}
    </select>
    )}


    <ModuleTodosSection filterRole={toggleRole} />
  </div>
}

const StudentSplashRightCol = (props) => {
  const { isFeatureEnabled, isCodeInPlaceCourse} = useContext(CourseContext)
  const isSectionEnabled = isFeatureEnabled("section")
  

  return (
    <div style={{ marginTop: '12px' }}>
      {/*  */}
      {/* <SplashCardRight title="Coming Up" content={<ComingUpCard />} /> */}
      <SplashCardRight title="Your Profile" content={<ProfileCard />} />
      {isCodeInPlaceCourse && <SplashCardRight title="Instructors" content={<CourseCard />} />}

    </div>
  );
};
const ProfileCard = (props) => {
  const { userData } = useContext(ProfileContext);
  // console.log(userData);
  const courseId = useCourseId()
  if (!userData) return <></>;

  const certificateUrl = `/${courseId}/certificate`


  return (
    <>

      <UnstyledLink to={`/${courseId}/profile`}>{userData?.displayName}
        &nbsp;&nbsp;
        <LocationInfo
          country={userData.country}
        ></LocationInfo>
      </UnstyledLink>
      <div className=" profileCard">
        <Link to={`/${courseId}/profile`}>
          <ProfilePicture />
        </Link>
      </div>
    </>
  );
};

export const ProfilePicture = () => {
  const { userData } = useContext(ProfileContext);
  if (!userData) return <></>
  if (!userData.photoURL && !userData.displayName) return <></>
  // if the photoURL is defined, show that image. Otherwise show the initials
  if (userData.photoURL) {
    return <img aria-label="Profile picture" className="fullSizeProfile" style={{ width: 150, height: 150 }} src={userData.photoURL}></img>
  } else {
    return <div aria-label="Profile picture" className="fullSizeProfile" style={{ width: 150, height: 150, backgroundColor: 'salmon', color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <span style={{ fontSize: '2rem' }}>{userData.displayName[0]}</span>
    </div>
  }
}





const CourseCard = (props) => {
  const courseId = useCourseId()
  const instructorList = ["Chris", "Mehran"];
  const isFoothill = courseId === "foothill-cs49" || courseId === "cs49-f24"
  if(isFoothill){
    instructorList.unshift("Lane")
  }
  let names = ''
  for (let i = 0; i < instructorList.length; i++) {
    names += instructorList[i]
    const isPenultimate = i === instructorList.length - 2
    const isLast = i === instructorList.length - 1
    if(isPenultimate){
      names += ' and '
    } else if (!isLast) {
      names += ', '
    } 
  }

  return (
    <>
      <div className="">{names}</div>

      <div className="row g-0">
        {
          instructorList.map((name, i) => {
            return <div className="col" style={{ maxWidth: 150 }}>
              <img className="fullSizeProfile" src={`/people/${name}.jpg`} />
            </div>
          })
        }
      </div>

      <div className="mt-1">
        A top <Link to="/team">Core Team</Link> and amazing section leaders
      </div>

      <img
        className="fullSizeImg"
        src="/cipmap2.jpg"
      />

      <div>
        Are we the class in the history of the world with the most teachers?
      </div>
    </>
  );
};

const UnstyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  &:hover {
    text-decoration: none;
    color: inherit;
  }
`;