import {  useContext, useState, useEffect, useRef } from "react";
import { IDEContext } from "../contexts/IDEContext";
import { Editor } from "@monaco-editor/react";
import { ProfileContext } from "contexts/ProfileContext";
import { addCommentBox } from "course/instructorFeedback/EditorZones";
import { setupButtonListeners } from "course/instructorFeedback/AddButton";
import { loadFeedbacks } from "course/instructorFeedback/FirebaseUtils";
import { FaEdit } from "react-icons/fa";
import Gate from "contexts/Gate";
import { CourseContext } from "contexts/CourseContext";
import { useCourseId } from 'hooks/router/useUrlParams';
import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore";

export const ResizableCodeInput = ({ onChange, isReadOnly, code="", isOffline=false }) => {
  const { editorRef, editorLoaded, setEditorLoaded, editorFontSize, projectData } = useContext(IDEContext);
  const { courseHasGrades} = useContext(CourseContext) 
  const courseId = useCourseId();

  // Particularly used for inline comments
  const { userData } = useContext(ProfileContext);
  const viewZoneIds = useRef({}); 
  const domNodeRefs = useRef({}); 

  // determining the visibility of the comments
  const [visibility, setVisibility] = useState(false);
  const [visibilityLoaded, setVisibilityLoaded] = useState(false);
  const assnId = projectData.assnId
  const authorId = projectData.editors[0]
  const visibilityDocPath = `submissions/${courseId}/assignments/${assnId}/users/${authorId}/feedback/visibility`

  const db = getFirestore()

  // fetch comment visibility document from firestore for course with grades
  useEffect(() => {
    if (!courseHasGrades) {
      setVisibilityLoaded(true);
      return;
    }
    const fetchVisibility = async () => {
      const visibilityDocRef= doc(db, visibilityDocPath);
      const docSnap = await getDoc(visibilityDocRef);
      if (docSnap.exists()) {
        setVisibility(docSnap.data().visibility);
      } else {
        console.log("No visibility document found");
        // create grading document
        try {
          await setDoc(visibilityDocRef, {
            visibility: false
          });
          console.log("Visibility document created");
        } catch (error) {
          console.error("Error creating visibility document: ", error);
        }
      }
      setVisibilityLoaded(true);
    }

    fetchVisibility();

  }, [visibilityDocPath]);

  if (!visibilityLoaded) return <></>;

  const onMount = (editor) => {
    editorRef.current = editor;
    setEditorLoaded(!editorLoaded);

    // Add right-click command
    if(!courseHasGrades) return;

    
    editorRef.current.addAction({
      id: "add-comment-id",
      label: "Add Comment",
      contextMenuGroupId: "navigation",
      contextMenuOrder: 1.5,
      run: function (ed) {
        addCommentBox(true, editorRef, editor.getPosition().lineNumber, viewZoneIds, domNodeRefs, projectData, userData, courseId);
      },
    });

    // Setup button listeners for inline comments
    if (Gate.hasSectionLeaderRole(userData)){
      setupButtonListeners(editorRef, viewZoneIds, domNodeRefs, projectData, userData, courseId);
    }

    // Load inline comments from Firestore
    loadFeedbacks(courseId, projectData, editorRef, viewZoneIds, domNodeRefs, userData, visibility);
  }

  if(isOffline) {
    <div  className="h-100 w-100 d-flex overflow-hidden">
      <Editor
      height="100%"
      defaultLanguage="python"
      onChange={onChange}
      onMount={onMount}
      options={{
        fontSize: editorFontSize,
        minimap: { enabled: false },
        readOnly: isReadOnly,
      }}
      width={"100%"}
      value={code}
    /> 
  </div>
  }

  return <div  className="h-100 w-100 d-flex overflow-hidden">
      {Gate.hasSectionLeaderRole(userData) && (
        <button id="selectionButton"><FaEdit /></button>
      )}
      <Editor
      height="100%"
      defaultLanguage="python"
      onChange={onChange}
      onMount={onMount}
      options={{
        fontSize: editorFontSize,
        minimap: { enabled: false },
        readOnly: isReadOnly,
      }}
      width={"100%"}
    /> 
  </div>
}