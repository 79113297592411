import "firebase/compat/auth";
import "firebase/compat/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  FaBars,
  FaCog,
  FaSeedling,
  FaQuestionCircle,
  FaToolbox,
} from "react-icons/fa";
import { useCourseId } from "../../hooks/router/useUrlParams";
import { useWindowSize } from "react-use-size";
import { useProSidebar } from "react-pro-sidebar";
import styled from "styled-components";
import { Nav, Navbar as BootstrapNavbar, NavDropdown } from "react-bootstrap";
// import {LinkContainer} from 'react-router-bootstrap'
import { Link } from "react-router-dom";
import { signOut, getAuth } from "firebase/auth";

import { ProfileContext, Role } from "contexts/ProfileContext";
import { useContext, useEffect, useState } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Brand } from "components/Brand";
import { courseMaxWidth } from "course/constants/courseContants";
import { markKarmaAsViewed, useAllKarma } from "course/PeerLearning/KarmaUtils";
import { isMinimumRole, isStudent } from "contexts/ProfileUtil";

import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { CourseContext } from "contexts/CourseContext";
import { NotificationsNav } from "./notification/NotificationsNav";

const titleMap = {
  studenthome: "Home Page for Students",
  teachinghome: "Home Page for Section Leaders",
  mentorhome: "Home Page for Section Leader (Mentor)",
  adminhome: "Home Page for Admins",
  section: "Your Section",
  teacherslounge: "Teacher's Lounge",
  training: "Teacher Training Center",
  code: "Code Center",
  practice: "Examples Center",
  create: "Creative Center",
  monitorsections: "Monitor Sections",
  teachnowadmin: "TeachNow Dashboard",
  teachnowstatistics: "Your TeachNow Impact",
  learn: "Learning Center",
  transcriptfeedback: "Transcript Feedback",
  sectionforum: "Section Forum",
  forum: "Classwide Forum",
  stories: "Pathways into Coding",
  events: "Events",
  aboutcourse: "About Code in Place",
  home: "Home Page for Teachers",
  finalProjectProposal: "Final Project Proposal",
};

export const CourseNavbar = (props) => {
  const { broken } = useProSidebar();

  const { userData } = useContext(ProfileContext);
  const courseRole = userData?.courseRole;
  const canSeeKarma = isMinimumRole(courseRole, Role.SECTION_LEADER);
  const { isFeatureEnabled } = useContext(CourseContext);
  const sectionEnabled = isFeatureEnabled("section", canSeeKarma);

  if (broken) {
    return (
      <Navbar>
        <SettingsNav />
        {/* {canSeeKarma ? <KarmaNav /> : <></>} */}
        <NotificationsNav />
        <ProfileNav />
        <ToggleAside />
      </Navbar>
    );
  }
  return (
    <Navbar>
      <SettingsNav />
      {/* {canSeeKarma ? <KarmaNav /> : <></>} */}
      <NotificationsNav />
      <ProfileNav />
    </Navbar>
  );
};

export const ToggleAside = (props) => {
  const { broken, toggleSidebar } = useProSidebar();
  return (
    <div>
      {broken && (
        <button
          className="btn btn-outline-light"
          onClick={() => toggleSidebar()}
        >
          <FaBars />
        </button>
      )}
    </div>
  );
};

const MakeupSection = ({ courseRole }) => {
  const courseId = useCourseId();
  // not for the public course!
  if (courseId === "public") {
    return null;
  }
  // I don't want section leaders to get confused and think they can use this to change section times!
  if (!isStudent(courseRole)) {
    return null;
  }
  return (
    <LinkContainer to={`/${courseId}/makeupsection`}>
      <NavDropdown.Item>Makeup Section</NavDropdown.Item>
    </LinkContainer>
  );
};

const SectionTimeSwitch = ({ courseRole }) => {
  const courseId = useCourseId();
  // not for the public course!
  if (courseId === "public") {
    return null;
  }
  // I don't want section leaders to get confused and think they can use this to change section times!
  if (courseRole === Role.STUDENT || courseRole === Role.ADMIN) {
    return (
      <LinkContainer to={`/${courseId}/sectionswitch`}>
        <NavDropdown.Item>Section Time Switch</NavDropdown.Item>
      </LinkContainer>
    );
  }

  return <></>;
};

const SettingsNav = (props) => {
  const { userData } = useContext(ProfileContext);
  const courseRole = userData?.courseRole;
  const courseId = useCourseId();
  const icon = <FaCog className="mainNavButton" />;

  const BaseNav = (
    <div>
      <SectionTimeSwitch courseRole={courseRole} />
      {/* <MakeupSection courseRole={courseRole} /> */}

      {isMinimumRole(courseRole, Role.SECTION_LEADER) && (
        <>
          <LinkContainer to={`/${courseId}/sectioncovers`}>
            <NavDropdown.Item>Section Covers</NavDropdown.Item>
          </LinkContainer>
        </>
      )}
      {isMinimumRole(courseRole, Role.TA) && (
        <LinkContainer to={`/${courseId}/monitorsections`}>
          <NavDropdown.Item>Monitor Sections</NavDropdown.Item>
        </LinkContainer>
      )}
      <LinkContainer to={`/${courseId}/report`}>
        <NavDropdown.Item>Report</NavDropdown.Item>
      </LinkContainer>
    </div>
  );

  if (!isMinimumRole(courseRole, Role.ADMIN)) {
    return (
      <>
        <NavDropdown className="course-profile-dropdown" title={icon}>
          {BaseNav}
        </NavDropdown>
      </>
    );
  }
  return (
    <NavDropdown className="course-profile-dropdown" title={icon}>
      <LinkContainer to={`/${courseId}/assneditor`}>
        <NavDropdown.Item>Assn Editor</NavDropdown.Item>
      </LinkContainer>
      <LinkContainer to={`/${courseId}/lessoneditor/student`}>
        <NavDropdown.Item>Lesson Editor</NavDropdown.Item>
      </LinkContainer>
      <LinkContainer to={`/${courseId}/lessoneditor/teacher`}>
        <NavDropdown.Item>Training Editor</NavDropdown.Item>
      </LinkContainer>
      <LinkContainer to={`/${courseId}/slattendance`}>
        <NavDropdown.Item>SL Attendance</NavDropdown.Item>
      </LinkContainer>
      <LinkContainer to={`/${courseId}/docseditor`}>
        <NavDropdown.Item>Docs Editor</NavDropdown.Item>
      </LinkContainer>
      <LinkContainer to={`/${courseId}/setpermissions`}>
        <NavDropdown.Item>Set Permissions</NavDropdown.Item>
      </LinkContainer>
      <LinkContainer to={`/${courseId}/roadmapeditor`}>
        <NavDropdown.Item>Roadmap Editor</NavDropdown.Item>
      </LinkContainer>
      <LinkContainer to={`/${courseId}/admin`}>
        <NavDropdown.Item>Admin Panel</NavDropdown.Item>
      </LinkContainer>
      {BaseNav}
    </NavDropdown>
  );
};

const KarmaNav = () => {
  const KARMA_MULTIPLIER = 5;
  const courseId = useCourseId();
  const auth = getAuth();
  const [user] = useAuthState(auth);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [hasBeenViewed, setHasBeenViewed] = useState(false);
  const [allKarmaDocs, totalKarma] = useAllKarma(user.uid, courseId, 10);

  const viewedKarmaDocs = allKarmaDocs
    ? allKarmaDocs.filter((doc) => doc.hasBeenViewed)
    : [];
  const unviewedKarmaDocs = allKarmaDocs
    ? allKarmaDocs.filter((doc) => !doc.hasBeenViewed)
    : [];

  useEffect(() => {
    if (isDropdownOpen && !hasBeenViewed) {
      setHasBeenViewed(true);
    } else if (!isDropdownOpen && hasBeenViewed) {
      // Mark all unviewed notifications as viewed in Firebase
      const result = unviewedKarmaDocs.map((doc) => {
        if (!doc.hasBeenViewed) {
          console.log("Marking karma as viewed", doc.id);
          return markKarmaAsViewed(user.uid, courseId, doc.id);
        } else {
          return Promise.resolve();
        }
      });
      Promise.all(result).then(() => setHasBeenViewed(false));
    }
  }, [isDropdownOpen, unviewedKarmaDocs]);

  const handleDropdownToggle = (isOpen) => {
    setIsDropdownOpen(isOpen);
  };

  if (!allKarmaDocs || totalKarma === 0) {
    return <></>;
  }

  const renderTooltip = (props) => (
    <Tooltip id="question-mark-tooltip" {...props}>
      Students who thanked you for helping out.
    </Tooltip>
  );

  const messageForKarma = (doc) => {
    if (doc.event == "peerGratitude") {
      return `${doc.senderDisplayName} thanked you for peer learning`;
    } else if (doc.event == "gratitude") {
      return `${doc.senderDisplayName} thanked you for your live help!`;
    } else {
      return "Someone thanked you for helping out!";
    }
  };

  return (
    <NavDropdown
      className="course-karma-dropdown"
      title={
        <>
          {/* <FaSeedling className="mainNavButton" /> */}
          <FaSeedling className="mainNavButton" />
          {unviewedKarmaDocs.some((doc) => !doc.hasBeenViewed) && (
            // <FaSquare className="karmaNotificationDot" />
            <div className="karmaNotificationDot">
              {unviewedKarmaDocs.length}
            </div>
          )}
        </>
      }
      onToggle={handleDropdownToggle}
    >
      <NavDropdown.Header key={"header"}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div>
            Your Gratitude
            {
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
              >
                <span style={{ marginLeft: "5px", cursor: "pointer" }}>
                  <FaQuestionCircle
                    style={{ backgroundColor: "transparent" }}
                  />
                </span>
              </OverlayTrigger>
            }
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              color: "dodgerblue",
            }}
          >
            <span style={{ fontSize: "20px", marginRight: "2px" }}>
              {totalKarma * KARMA_MULTIPLIER}
            </span>
            <FaSeedling size={14} />
          </div>
        </div>
      </NavDropdown.Header>

      {unviewedKarmaDocs.length > 0 ? (
        <>
          <NavDropdown.Divider
            key={"divider"}
            style={{ margin: 0, marginBottom: "2px" }}
          />
          <div style={{ marginBottom: "10px", marginTop: "10px" }}>
            {unviewedKarmaDocs.map((doc, index) => {
              const message = messageForKarma(doc);
              return (
                <NavDropdown.Item key={index}>
                  {
                    <span
                      style={{
                        backgroundColor: doc.hasBeenViewed
                          ? "transparent"
                          : "rgb(26, 171, 53)",
                      }}
                      className="karmaPlusOne"
                    >
                      &nbsp;
                      {doc.hasBeenViewed ? "" : `+${KARMA_MULTIPLIER}`}
                      &nbsp;
                    </span>
                  }
                  {message}
                </NavDropdown.Item>
              );
            })}
          </div>
        </>
      ) : null}
      {viewedKarmaDocs.length > 0 ? (
        <>
          <NavDropdown.Divider
            key={"divider"}
            style={{ margin: 0, marginBottom: "2px" }}
          />
          <div style={{ marginTop: "10px" }}>
            {viewedKarmaDocs.map((doc, index) => {
              const message = messageForKarma(doc);
              return (
                <NavDropdown.Item key={index}>
                  {
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "rgb(26, 171, 53)",
                      }}
                      className="karmaPlusOne"
                    >
                      &nbsp; {`+${KARMA_MULTIPLIER}`} &nbsp;
                    </span>
                  }
                  {message}
                </NavDropdown.Item>
              );
            })}
          </div>
        </>
      ) : null}
    </NavDropdown>
  );
};

const Navbar = ({ children }) => {
  const windowSize = useWindowSize();
  const { courseName } = useContext(CourseContext);
  const isMobile = windowSize.width < 768;
  const courseId = useCourseId();
  const isFoothill = courseId === "foothill-cs49" || courseId === "cs49-f24";
  const brandTo = `/${courseId}/studenthome`;
  const stanfordTo = isFoothill ? brandTo : "/";
  const title = courseName;
  const university = isFoothill ? "Stanford & Foothill" : "Stanford";
  return (
    <NavbarWrapper bg="dark" variant="dark">
      <BootstrapNavbar.Toggle aria-controls="basic-navbar-nav" />
      <BootstrapNavbar.Collapse
        id="basic-navbar-nav"
        className="justify-content-center"
      >
        <div
          className="d-flex justify-content-between align-items-center w-100"
          style={{ maxWidth: courseMaxWidth }}
        >
          {/* left size */}
          <Nav>
            <BootstrapNavbar.Brand>
              <Link to={brandTo}>
                <Brand
                  stanfordTo={stanfordTo}
                  title={title}
                  university={university}
                />
              </Link>
            </BootstrapNavbar.Brand>
          </Nav>

          <CenterTitle />

          {/* right side */}
          {children && (
            <NavIcons
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                // TODO: @TJ can you make this a ref so that this width matches left size -- Still need to do this maybe... quick fix implemented
                minWidth: isMobile ? "0px" : "260px",
              }}
            >
              {children}
            </NavIcons>
          )}
        </div>
      </BootstrapNavbar.Collapse>
    </NavbarWrapper>
  );
};

const CenterTitle = () => {
  const { userData } = useContext(ProfileContext);
  const isTeacher = isMinimumRole(userData.courseRole, Role.SECTION_LEADER);
  // dont show this if the page is too small
  const { width } = useWindowSize();
  if (width < 800) {
    return null;
  }
  const currSubpage = location.pathname.split("/")[2];

  let title = getTitle(currSubpage, isTeacher);

  return <Nav style={centerTitleStyle}>{title}</Nav>;
};

function getTitle(subpage, isTeacher) {
  // special case the student home page if student (thats the only)
  // home page they have
  if (!isTeacher && subpage === "studenthome") {
    return "Course Home";
  }

  return titleMap[subpage] || toTitleCase(subpage);
  // if (subpage in titleMap) {
  //   return titleMap[subpage];
  // }
  // return isTeacher ? "Teacher's Lounge" : "Learning Center";
}

const NavbarWrapper = styled(BootstrapNavbar)`
  background-color: rgb(89, 64, 203) !important;
  padding-left: 15px;
  padding-right: 15px;
  z-index: 1;
  height: 45px;
`;

// rgb(208, 224, 255)
// rgb(89, 64, 203) nice purple
// #353a3f

const NavIcons = styled(Nav)`
  display: flex;
  alignitems: center;
`;

/* ProfileNav is a navigation icon so that users can manage their log-in
 * state and view their profile. It can only be used on a page that's wrapped
 * in ProfileProvider.
 */
export const ProfileNav = () => {
  const auth = getAuth();
  const { userData } = useContext(ProfileContext);

  const photoUrl = userData ? userData.photoURL : "";
  const displayName = userData ? userData.displayName : "A";
  const courseId = useCourseId();
  return (
    <NavDropdown
      className="course-profile-dropdown"
      aria-label="Profile dropdown"
      title={
        <UserIcon
          photoURL={photoUrl}
          name={displayName}
          aria-label="Profile dropdown"
        />
      }
    >
      <LinkContainer to={`/${courseId}/profile`}>
        <NavDropdown.Item>Profile</NavDropdown.Item>
      </LinkContainer>
      <NavDropdown.Item
        onClick={() => {
          signOut(auth);
        }}
      >
        Log Out
      </NavDropdown.Item>
    </NavDropdown>
  );
};

const centerTitleStyle = {
  color: "#D4D1D1",
  fontWeight: 600,
  fontSize: 18,
};

function toTitleCase(str) {
  if (!str) {
    return "";
  }
  var words = str.toLowerCase().split(" ");
  for (var i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }
  return words.join(" ");
}

const UserIcon = ({ photoURL, name }) => {
  if (photoURL) {
    return (
      <img
        src={photoURL}
        width="30"
        height="30"
        className="rounded-circle"
        style={{ objectFit: "cover" }}
      />
    );
  }

  // draw a salmon circle with the letter C in the middle
  let initial = "";
  if (name) {
    if (name.length > 0) {
      initial = name[0];
    }
  }
  if (!initial) {
    initial = "S";
  }

  return (
    <div style={{ display: "inline-block" }}>
      <div className="rounded-circle" style={userIconStyle}>
        {initial}
      </div>
    </div>
  );
};

const userIconStyle = {
  width: "30px",
  height: "30px",
  // display: 'inline-block',
  color: "white",
  borderRadius: "50%",
  backgroundColor: "salmon",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "24px",
  position: "relative",
};
