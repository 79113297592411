import { useState} from "react";
import {  Button } from "react-bootstrap";
import { doc, setDoc } from "firebase/firestore";
import { FaEye, FaEyeSlash } from "react-icons/fa"
import Swal from "sweetalert2";

export const GlobalCommentVisibilityToggle = ({
  db,
  courseId,
  assnId,
  studentIds,
}: {
  db: any 
  courseId: string;
  assnId: string;
  studentIds: string[];
}) => {
  const [submitLoading, setSubmitLoading] = useState(false)

  const setVisibility = async (newVal) => {
    setSubmitLoading(true);
    const promises = studentIds.map((studentId) => {
      const firebasePath = `submissions/${courseId}/assignments/${assnId}/users/${studentId}/feedback/visibility`;
      const docRef = doc(db, firebasePath);
      return setDoc(docRef, { visibility: newVal });
    });

    await Promise.all(promises);
    setSubmitLoading(false);
    Swal.fire({
      title: "Success!",
      text: `Comments visibility set to ${newVal} for all students`,
      icon: "success",
    })
  }

  return (
    <>
      <Button variant="light" onClick={() => {setVisibility(false)}} disabled={submitLoading}>
        Hide comments&nbsp;
        <FaEyeSlash />
      </Button>
      <Button variant="light" onClick={() => {setVisibility(true)}} disabled={submitLoading}>
        Release comments&nbsp;
        <FaEye />
      </Button>
    </>
  );
}

export const CommentVisibilityToggle = ({
  db,
  courseId,
  assnId,
  studentId,
  currentVisibility
}: {
  db: any 
  courseId: string;
  assnId: string;
  studentId: string;
  currentVisibility: boolean;
}) => {
  const [commentsVisible, setCommentsVisible] = useState(currentVisibility)
  const [submitLoading, setSubmitLoading] = useState(false)

  const setVisibility = async (newVal) => {
    setSubmitLoading(true);
    const firebasePath = `submissions/${courseId}/assignments/${assnId}/users/${studentId}/feedback/visibility`;
    const docRef = doc(db, firebasePath);
    await setDoc(docRef, { visibility: newVal });
    setCommentsVisible(newVal);
    setSubmitLoading(false);
  }

  return (
    <>
      <Button variant="light" onClick={() => {setVisibility(!commentsVisible)}} disabled={submitLoading}>
        {commentsVisible ? "Hide" : "Show"} comments&nbsp; {commentsVisible ? <FaEyeSlash /> : <FaEye />}
      </Button>
    </>
  );
}