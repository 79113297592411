import React, { useContext, useEffect, useState } from 'react'
import { RoadmapContext } from 'course/contexts/RoadmapContext'
import BootstrapTable from 'react-bootstrap-table-next';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import { FaLink } from 'react-icons/fa';
import { collectAssignments } from 'course/code/RoadmapAssignments';
import { useCourseId } from 'hooks/router/useUrlParams';
import { CoursePageBodyContainer } from 'components/layout/CoursePageBodyContainer';
import Gate from 'contexts/Gate';
import { ProfileContext } from "contexts/ProfileContext";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getLocalUTCTimezone, parseAndFormatISODate } from 'components/timezones/timezoneHelper';
import { PartialLoading } from 'components/loading/Loading';

const StickyHeaderTable = styled.div`
    .react-bootstrap-table {
        thead {
            position: sticky;
            top: 0;
        }
    }
`;

const functions = getFunctions();
// not using this for now, but if we want to display e.g. number of submissions, number of graded submissions, etc. we can use this
// const adminGetAssnSubmissions = httpsCallable(functions, 'adminGetAssnSubmissions');
const getAssnInfo = httpsCallable(functions, 'getAssnInfo');

export const GradingSplash = () => {
    // linked to from admin home page if course is Foothill (TODO @miranda: this is hardcoded for now)
    const courseId = useCourseId()
    const { userData } = useContext(ProfileContext);
    const { getAssignmentsInRoadmap, roadmapItemMap } = useContext(RoadmapContext)
    const [assignments, setAssignments] = useState([]) // [
    const [projectsList, metaData] = collectAssignments(assignments)

    const [data, setData] = useState([])
    const [dataLoading, setDataLoading] = useState(true)

    useEffect(() => {
        const assns = getAssignmentsInRoadmap()
        setAssignments(assns)
    }, [roadmapItemMap])

    useEffect(() => {
        if (!metaData) return
        const newData = Object.entries(metaData).map(([key, value]) => value)
        setData(newData)

        const fetchCanvasData = async () => {
            const dataWithCanvasInfo = await Promise.all(
                newData.map(async (item) => {
                if (item.canvasAssnId) {
                    try {
                        const res = await getAssnInfo({
                            adminId: userData.id,
                            courseId: courseId,
                            assnId: item.assnId 
                        })
                        const fetchedData = res.data
                        return { ...item, ...fetchedData };  // Merge fetched data with existing dictionary
                    } catch (error) {
                        console.error('Error fetching admin information:', error);
                        return item;  // Return the item as-is if there's an error
                    }
                } else {
                    return item; // Return the item as-is if no canvasAssnId exists
                }
                })
            );
            setData(dataWithCanvasInfo);
            setDataLoading(false);
        }
        fetchCanvasData();
    }, [metaData])

    if (dataLoading || !metaData || !projectsList) return <PartialLoading />

    return (
      <Gate when={Gate.hasAdminRole}>
        <CoursePageBodyContainer
            mainColumn={<GradingTable courseId={courseId} data={data}/>}
            rightColumn={<></>}
            singleColumn={<GradingTable courseId={courseId} data={data}/>}
        />
      </Gate>
    )
}

export const GradingTable = ({ courseId, data }) => {
    const navigate = useNavigate()
    const userTz = getLocalUTCTimezone()
    // table with all assignments and link to pages where you can download student submissions for grading.
    const columns = [
    {
        dataField: 'assnId',
        text: 'IDE',
        formatter: (cell, row) => {
            return <Link to={`/${courseId}/ide/a/${cell}`}><FaLink /></Link>
        }
    },
    {
        dataField: 'title',
        text: 'Assignment'
    },
    {
        dataField: 'assnGroup',
        text: 'Unit',
        sort: true
    },
    {
        dataField: 'type',
        text: 'Type',
        sort: true
    },
    {
        dataField: 'due_at',
        text: 'Due Date',
        sort: true,
        formatter: (cell) => {
            if (!cell) return ''
            return parseAndFormatISODate(cell, userTz)
        }
    },
    {
        dataField: 'points_possible',
        text: 'Points',
        sort: true
    },
    {
        dataField: 'needs_grading_count',
        text: 'Needs grading',
        sort: true,
        formatter: (cell) => {
            // if cell is non-zero, make text bold and primary blue
            if (cell > 0) {
                return <strong style={{color: '#316CF4'}}>{cell}</strong>
            } else {
                return cell
            }
        }
    }
    ]
    const tableRowEvents = {
        onClick: (e, row, rowIndex) => {
            navigate(`/${courseId}/grading/${row.assnId}`)
        },
        onMouseEnter: (e, row, rowIndex) => {
            e.target.style.cursor = 'pointer';
        }
     }

    return (
        <StickyHeaderTable>
            <BootstrapTable 
                bordered={false} 
                striped={true} 
                hover={true} 
                bootstrap4={true} 
                keyField='assnId' 
                data={data} 
                columns={columns} 
                rowEvents={tableRowEvents}
            />
        </StickyHeaderTable>
    )
}